import React, { useState } from 'react';
import * as S from './Contact.style';
import { useSendEmailMutation } from '../../../redux/Geegee.api';

const Contact: React.FC = () => {
  const [sendEmail, { isError, isLoading, isSuccess, isUninitialized }] =
    useSendEmailMutation();
  const [email, setEmail] = useState<string>('');

  return (
    <>
      <S.EmailContainer isSuccess={isSuccess}>
        {!(!isUninitialized && isSuccess) && (
          <S.InputWrapper>
            <S.IconEmail>mail</S.IconEmail>
            <S.Input
              type="email"
              placeholder="Your email"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              disabled={isLoading || isSuccess}
            />
          </S.InputWrapper>
        )}
        <S.GetInTouchButton
          onClick={() => {
            if (!isUninitialized && isSuccess) return;
            sendEmail({ email });
          }}
          disabled={isLoading || isSuccess}
          isLoading={isLoading}
          isSuccess={isSuccess}
        >
          {isLoading ? (
            <S.Spinner />
          ) : isSuccess ? (
            "We'll be in touch!"
          ) : (
            'Get in touch'
          )}
        </S.GetInTouchButton>
      </S.EmailContainer>
      {isError && (
        <S.ErrorMessage>Something went wrong. Please try again.</S.ErrorMessage>
      )}
    </>
  );
};

export default Contact;
