import React from 'react';
import * as S from './VideoSection.style';
import useScreen from '../../hooks/useScreen';

const VideoSection: React.FC = () => {
  const { isMobile, isTablet, isDesktop } = useScreen();
  const sizeVideo = () => {
    if (isDesktop) return { width: 600, height: 340 };
    if (isTablet) return { width: 500, height: 281 };
    if (isMobile) return { width: 350, height: 198 };
  };

  return (
    <S.VideoSectionContainer>
      <S.Title>Here’s how it works</S.Title>
      <iframe
        width={sizeVideo()?.width}
        height={sizeVideo()?.height}
        src="https://www.youtube.com/embed/5wSHXaffkEY?si=QHHwwcXKqfmO9wcx&amp;controls=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </S.VideoSectionContainer>
  );
};

export default VideoSection;
