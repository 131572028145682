// Styles for Footer
import styled from 'styled-components';
import { media } from '../../styles/breakpoint';

export const Container = styled.div`
  background: #19274c;
  display: flex;
  align-items: center;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 100px;

  @media ${media.tablet} {
    height: 70px;
  }

  @media ${media.mobile} {
    height: 70px;
  }
`;

export const Wrapper = styled.div`
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  width: calc(100% - 214px);
  margin: 0 auto;

  @media ${media.tablet} {
    width: calc(100% - 80px);

    &.logo {
      margin-left: 80px;
    }
  }
  @media ${media.mobile} {
    width: calc(100% - 42px);
  }
`;
