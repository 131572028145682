/* eslint-disable import/no-extraneous-dependencies */
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

const url = process.env.API_URL || '';

export const GeegeeApi = createApi({
  reducerPath: 'GeegeeApi',
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    sendEmail: builder.mutation<string, { email: string }>({
      query: (email) => {
        return {
          url: `${url}/api/send-email`,
          body: email,
          method: 'POST',
        };
      },
    }),
  }),
});

export const { useSendEmailMutation } = GeegeeApi;
