// Styles for Explanation
import styled from 'styled-components';
import { media } from '../../styles/breakpoint';

export const Description = styled.p<{
  weight?: number;
  size?: number;
  align?: 'center' | 'left';
}>`
  font-size: ${({ size }) => size || 16}px;
  color: #19274c;
  margin: 0;
  line-height: 1.5;
  text-align: ${({ align }) => align || 'center'};
  font-weight: ${({ weight }) => weight || 500};

  @media ${media.tablet} {
    text-align: left;
  }
  @media ${media.desktop} {
    text-align: left;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-wrap: wrap;
  @media ${media.desktop} {
    flex: 1;
  }
`;

export const Icon = styled.span`
  font-family: 'Material Symbols Outlined';
  font-size: 28px;
  font-weight: 600;
  color: #19274c;
`;

export const List = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-basis: calc(50% - 8px);
  box-sizing: border-box;
`;

export const ContainerList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  @media ${media.tablet} {
    align-items: flex-start;
    gap: 16px;
    padding: 0 48px;
  }

  @media ${media.mobile} {
    align-items: flex-start;
    gap: 16px;
    padding: 0 16px;
  }

  @media ${media.desktop} {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efeff2;
  max-width: 1280px;
  width: calc(100% - 214px);
  padding: 48px 0;
  margin: 0 auto;
  gap: 24px;

  @media ${media.tablet} {
    flex-direction: column;
    align-items: center;
    width: calc(100% - 80px);
  }

  @media ${media.mobile} {
    flex-direction: column;
    align-items: center;
    width: calc(100% - 15px);
  }
`;

export const ContainerExplanation = styled.div`
  background: #efeff2;
`;
