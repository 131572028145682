import Explanation from './features/pages/Explanation';
import Footer from './features/pages/Footer';
import Presentation from './features/pages/Presentation/Presentation';
import Providers from './features/pages/Providers';
import VideoSection from './features/pages/VideoSection';
import { store } from './redux/store';
import { Provider } from 'react-redux';

function App() {
  return (
    <Provider store={store()}>
      <Presentation />
      <VideoSection />
      <Explanation />
      <Providers />
      <Footer />
    </Provider>
  );
}

export default App;
