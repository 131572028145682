import React from 'react';
import * as S from './Providers.style';

const Providers: React.FC = () => {
  return (
    <S.ProvidersContainer>
      <S.Container>
        <S.Paragraph size={32} weight={700}>
          What we provide
        </S.Paragraph>
        <S.Wrapper>
          <S.Card>
            <S.Icon>shopping_cart</S.Icon>
            <S.Paragraph size={32} weight={700}>
              Ad Exchange
            </S.Paragraph>
            <S.Paragraph style={{ marginBottom: 16 }}>
              We enable content publishers to offer new subscription plans based
              on ppx inventory (pay per experience).
            </S.Paragraph>
            <S.Paragraph>
              We are the only exchange offering this innovative new media type.
              Advertisers directly pay for visitors in their game or virtual
              world. Our platform tracks order fulfillment and records key user
              behavior data.
            </S.Paragraph>
          </S.Card>
          <S.Card>
            <S.Icon>signal_cellular_alt</S.Icon>
            <S.Paragraph size={32} weight={700}>
              Demand Side Platform
            </S.Paragraph>
            <S.Paragraph style={{ marginBottom: 16 }}>
              Enables advertisers to optimize each user’s experience based on
              past behavior “Personalized Pathways”.
            </S.Paragraph>
            <S.Paragraph>
              Audience targeting, data integrations, analytics and reporting -
              all customized for our exclusive ppx media type.
            </S.Paragraph>
          </S.Card>
        </S.Wrapper>
      </S.Container>
    </S.ProvidersContainer>
  );
};

export default Providers;
