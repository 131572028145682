import styled from 'styled-components';

export const VideoSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to bottom, #19274c, #efeff2);
  padding: 64px;
  gap: 48px;
`;

export const Title = styled.h1`
  color: white;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  text-align: center;
`;
