export const sizes = {
  mobile: '320px',
  tablet: '768px',
  desktop: '1024px',
};

export const media = {
  mobile: `(min-width: ${sizes.mobile}) and (max-width: 768px)`,
  tablet: `(min-width: ${sizes.tablet}) and (max-width: 1024px)`,
  desktop: `(min-width: ${sizes.desktop})`,
};
