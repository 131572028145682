import React from 'react';
import * as S from './Explanation.style';
import MobileExplanation from '../../components/MobileExplanation';
import useScreen from '../../hooks/useScreen';

const Explanation: React.FC = () => {
  const { isMobile, isTablet, isDesktop } = useScreen();

  return (
    <S.ContainerExplanation>
      <S.Container>
        {(isMobile || isTablet) && (
          <S.Description size={24} weight={700}>
            Remove in-stream ads and replace them with sponsored game queues
          </S.Description>
        )}
        <div style={{ display: 'flex', justifyContent: 'center', flex: 2 }}>
          <MobileExplanation />
        </div>
        <S.Wrapper>
          {isDesktop && (
            <S.Description size={24} weight={700}>
              Remove in-stream ads and replace them with sponsored game queues
            </S.Description>
          )}
          <S.ContainerList>
            <S.List>
              <S.Icon>task_alt</S.Icon>
              <S.Description align="left">fun user experience</S.Description>
            </S.List>
            <S.List>
              <S.Icon>task_alt</S.Icon>
              <S.Description align="left">100% engagement</S.Description>
            </S.List>
            <S.List>
              <S.Icon>task_alt</S.Icon>
              <S.Description align="left">
                no content interruptions
              </S.Description>
            </S.List>
            <S.List>
              <S.Icon>task_alt</S.Icon>
              <S.Description align="left">
                no more SKIP AD button!
              </S.Description>
            </S.List>
          </S.ContainerList>
        </S.Wrapper>
      </S.Container>
    </S.ContainerExplanation>
  );
};

export default Explanation;
