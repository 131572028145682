import React from 'react';
import * as S from './Footer.style';
import logo from './logo.png';
import useScreen from '../../hooks/useScreen';

const Footer: React.FC = () => {
  const { isMobile, isTablet, isDesktop } = useScreen();
  const sizeLogo = () => {
    if (isDesktop) return 100;
    if (isTablet) return 100;
    if (isMobile) return 70;
  };

  return (
    <S.Container>
      <S.Wrapper className="logo">
        <img src={logo} width={sizeLogo() || 200} />
        <p style={{ margin: 0, paddingRight: 42 }}>© 2024 - geegee.dev</p>
      </S.Wrapper>
    </S.Container>
  );
};

export default Footer;
