import { media } from '../styles/breakpoint';
import useMediaQuery from './useMediaQuery';

const useScreen = () => {
  const isTablet = useMediaQuery(media.tablet);
  const isMobile = useMediaQuery(media.mobile);
  const isDesktop = useMediaQuery(media.desktop);

  return {
    isTablet,
    isMobile,
    isDesktop,
  };
};

export default useScreen;
