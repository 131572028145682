import React from 'react';
import * as S from './MobileExplanation.style';
import useScreen from '../../hooks/useScreen';

const MobileExplanation: React.FC = () => {
  const { isMobile, isTablet, isDesktop } = useScreen();
  const sizeSkeleton = () => {
    if (isDesktop) return '170px';
    if (isTablet) return '110px';
    if (isMobile) return '40px';
  };
  return (
    <S.MobileExplanation>
      <S.Notification style={{ height: '80%' }}>
        <S.Wrapper>
          <div>
            <S.Icon>priority_high</S.Icon>
          </div>
          <S.DescriptionContainer>
            <S.Description weight={700}>
              You have new games to play!
            </S.Description>
            <S.Description>unlock free streaming</S.Description>
          </S.DescriptionContainer>
        </S.Wrapper>
        <S.Button>
          <S.Description weight={700}>Play!</S.Description>
        </S.Button>
      </S.Notification>
      <S.Notification style={{ height: '100%' }}>
        <S.ListGames>
          <S.PlayButton>
            <S.Description weight={700}>Play!</S.Description>
          </S.PlayButton>
          <div>
            <S.Skeleton width={sizeSkeleton()} />
            <S.Skeleton width="70%" />
          </div>
        </S.ListGames>
        <S.ListGames>
          <S.PlayButton>
            <S.Description weight={700}>Play!</S.Description>
          </S.PlayButton>
          <div>
            <S.Skeleton width={sizeSkeleton()} />
            <S.Skeleton width="70%" />
          </div>
        </S.ListGames>
        <S.ListGames>
          <S.PlayButton>
            <S.Description weight={700}>Play!</S.Description>
          </S.PlayButton>
          <div>
            <S.Skeleton width={sizeSkeleton()} />
            <S.Skeleton width="70%" />
          </div>
        </S.ListGames>
      </S.Notification>
    </S.MobileExplanation>
  );
};

export default MobileExplanation;
