import styled from 'styled-components';
import { media } from '../../styles/breakpoint';

export const Menu = styled.div`
  background: linear-gradient(
    to top right,
    rgba(194, 254, 254, 0.8),
    rgba(255, 255, 255, 0.8)
  );
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;

  @media ${media.tablet} {
    height: 70px;
  }

  @media ${media.mobile} {
    height: 70px;
  }
`;
// height: 100vh;
export const PresentationContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom right, #c2fefe, #ffffff);
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 150px 64px;

  @media ${media.tablet} {
    gap: 24px;
    padding: 70px 80px;
  }
  @media ${media.mobile} {
    gap: 16px;
    padding: 70px 24px;
    flex-direction: column;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;

  @media ${media.tablet} {
    flex: 1.8;
  }

  @media ${media.desktop} {
    flex: 1.8;
  }
`;

export const Img = styled.div`
  flex: 2;
  display: flex;
  justify-content: flex-end;
`;

export const Title = styled.h1`
  font-size: 90px;
  color: #19274c;
  line-height: 1.1;

  @media ${media.tablet} {
    font-size: 64px;
  }

  @media ${media.mobile} {
    font-size: 40px;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  color: #19274c;
  margin-top: 0;

  @media ${media.tablet} {
    font-size: 16px;
  }
`;

export const Wrapper = styled.div`
  max-width: 1280px;
  width: calc(100% - 214px);
  margin: 0 auto;

  @media ${media.tablet} {
    width: calc(100% - 80px);

    &.logo {
      margin-left: 80px;
    }
  }
  @media ${media.mobile} {
    width: calc(100% - 24px);
  }
`;
