// Styles for Providers
import styled from 'styled-components';
import { media } from '../../styles/breakpoint';

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 32px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  align-items: center;
  justify-content: center;
  gap: 56px;
  background: #efeff2;
  max-width: 1280px;
  width: calc(100% - 214px);
  margin: 0 auto;

  @media ${media.tablet} {
    align-items: center;
    width: calc(100% - 80px);
  }

  @media ${media.mobile} {
    align-items: center;
    width: calc(100% - 15px);
  }
`;

export const ProvidersContainer = styled.div`
  background: #efeff2;
`;

export const Paragraph = styled.p<{
  weight?: number;
  size?: number;
}>`
  font-size: ${({ size }) => size || 16}px;
  color: #19274c;
  margin: 0;
  line-height: 1.5;
  font-weight: ${({ weight }) => weight || 500};
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 32px;

  @media ${media.tablet} {
  }

  @media ${media.mobile} {
    flex-direction: column;
    padding: 0 16px;
  }
`;

export const Icon = styled.span`
  font-family: 'Material Symbols Outlined';
  font-size: 32px;
  font-weight: 600;
  color: #19274c;
`;
