import styled, { css } from 'styled-components';

export const EmailContainer = styled.div<{ isSuccess: boolean }>`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  border: 1px solid #c2c2c2;

  ${({ isSuccess }) =>
    isSuccess &&
    css`
      background-color: transparent;
      box-shadow: none;
      border: none;
    `}
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  flex-grow: 1;
  min-width: 0;
`;

export const IconEmail = styled.span`
  font-family: 'Material Symbols Outlined';
  font-size: 20px;
  color: #b3b3b3;
  margin-right: 8px;
`;

export const Input = styled.input`
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 16px;
  color: #b3b3b3;

  &::placeholder {
    color: #b3b3b3;
  }

  min-width: 0;
`;

export const GetInTouchButton = styled.button<{
  isLoading: boolean;
  isSuccess: boolean;
}>`
  background-color: #1e2b50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 40px;
  cursor: pointer;
  white-space: nowrap;
  margin-left: 10px;
  flex-shrink: 0;
  max-width: 100%;

  ${({ isSuccess }) =>
    isSuccess &&
    css`
      cursor: not-allowed;
    `}

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.isLoading || props.isSuccess
      ? css`
          width: auto;
        `
      : css`
          width: auto;
          min-width: 120px;
        `}
`;

export const EmailContainerFocus = styled(EmailContainer)`
  &:focus-within {
    border-color: #1e2b50;
  }
`;

export const Spinner = styled.div`
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;
